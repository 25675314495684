<template>
    <v-app>
        <div class="login__container">
            <div class="center">
                <div class="logo">
                    <img
                        src="./../../assets/img/global/repairs/e_r_logo_white.png"
                        alt="Logo Entelli"
                    />
                </div>
                <div class="slogan">
                    <span>information driven platform</span>
                </div>
                <transition name="fade">
                    <div key="1" v-if="userName" ref="loginForm">
                        <form>
                            <div class="txt_field">
                                <input
                                    v-model="form.login"
                                    ref="login"
                                    :rules="[rules.required]"
                                    @keyup.enter="tryLogin"
                                    type="text"
                                    required
                                />
                                <label>Login</label>
                                <span></span>
                            </div>
                            <div class="error-message">
                                {{ this.errors }}
                            </div>

                            <div class="txt_field">
                                <input
                                    :label="$t('password')"
                                    color="primary"
                                    :append-icon="
                                        show
                                            ? 'mdi-eye-outline'
                                            : 'mdi-eye-off-outline'
                                    "
                                    :type="show ? 'text' : 'password'"
                                    @click:append="show = !show"
                                    v-model="form.password"
                                    :rules="[rules.required]"
                                    @keyup.enter="tryLogin"
                                    required
                                />
                                <span></span>
                                <label>Hasło</label>
                            </div>
                            <div class="error-message">
                                {{ this.errors }}
                            </div>
                        </form>
                        <div class="buttons">
                            <v-btn
                                class="buttons ma-2"
                                data-cy="reset"
                                @click="showRecovery = true"
                                >{{ $t('login:reset') }}</v-btn
                            >

                            <v-btn
                                class="buttons buttons--main ma-2"
                                data-cy="submit"
                                v-bind:disabled="
                                    form.login === '' || form.password === ''
                                "
                                @click="tryLogin"
                                >{{ $t('login:log-in') }}</v-btn
                            >
                        </div>
                    </div>

                    <div key="2" v-else ref="loginForm">
                        <form>
                            <div class="greetings">
                                <h2 class="font-weight-light greetings-text">
                                    {{ $t('login:greeting') }}
                                    {{ $get(user, 'name', '') }}
                                    {{ $get(user, 'lastname', '') }}
                                </h2>
                            </div>
                        </form>
                    </div>
                </transition>
                <div class="footer">
                    <a
                        class="login__panel__footer font-weight-light caption"
                        href="https://www.biuromax-balcer.pl"
                        target="_blank"
                        >Powered by Biuromax-Balcer</a
                    >
                </div>
            </div>
        </div>

        <div class="version">Version 3.3</div>
        <v-menu
            min-width="400"
            v-model="showRecovery"
            content-class="recoveryModal"
            :close-on-content-click="false"
        >
            <v-card class="pa-4">
                <h4 class="primary--text pb-4">
                    {{ $t('login:recoverLoginAndPassword') }}
                </h4>
                <v-row>
                    <v-col cols="6">
                        <v-text-field
                            dense
                            v-model="reset.fields.nameAndLastname"
                            :error-messages="reset.errors.nameAndLastname"
                            :label="$t('login:nameAndLastname')"
                        ></v-text-field
                    ></v-col>
                    <v-col cols="6">
                        <v-text-field
                            dense
                            v-model="reset.fields.email"
                            :error-messages="reset.errors.email"
                            :label="$t('login:email')"
                        ></v-text-field
                    ></v-col>
                </v-row>
                <v-row
                    ><v-col cols="6">
                        <v-text-field
                            dense
                            v-model="reset.fields.phone"
                            :label="$t('login:phone')"
                        ></v-text-field></v-col
                    ><v-col cols="6">
                        <v-text-field
                            dense
                            
                            v-model="reset.fields.login"
                            :label="$t('login:login')"
                        ></v-text-field></v-col
                ></v-row>

                <v-row>
                    <v-col cols="12">
                        <v-text-field
                            dense
                            v-model="reset.fields.contents"
                            :label="$t('login:contents')"
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-col cols="12" class="d-flex justify-end pa-0">
                    <v-btn
                        class="buttons buttons--add"
                        @click="resetPassword(reset)"
                        >{{ $t('login:send') }}</v-btn
                    >
                </v-col>
            </v-card>
        </v-menu>
    </v-app>
</template>

<script>
import i18next from 'i18next'
import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import Rest from '@/plugins/Rest'
export default {
    i18nOptions: { namespaces: ['login'] },
    data() {
        return {
            showRecovery: false,
            form: {
                login: '',
                password: '',
            },
            reset: {
                fields: {
                    nameAndLastname: '',
                    email: '',
                    phone: '',
                    login: '',
                    contents: '',
                },
                errors: {
                    nameAndLastname: '',
                    email: '',
                },
            },
            rules: {
                required: value => !!value || this.$t('login:error'),
            },
            show: false,
            imgLogin: null,
            number: null,
            userName: true,
            user: '',
            errors: '',
        }
    },
    computed: {
        ...mapGetters(['getLogoutModal']),
    },
    mounted() {
    this.focus()
    },
    methods: {
        ...mapActions(['startSessionTimer', 'sendMail']),
        ...mapMutations([
            'setPicture',
            'setSnackbars',
            'setUserSession',
            'setPicture',
        ]),
        closeConfirmModal() {
            this.getLogoutModal.open = false
        },
        focus() {
            this.$refs.login.focus()            
        },
        // === CUSTOME IMAGE ON LEFT === //
        // getImgUrl() {
        //     this.number = Math.floor(Math.random() * 13) + 1
        //     this.imgLogin = require('@/assets/img/login/' +
        //         this.number +
        //         '.svg')
        // },
        validationMenu(reset) {
            for (const req in reset.errors) {
                if (reset.fields[req] == '') {
                    reset.errors[req] = `${this.$t('global:errorFields')}!`
                    this.setSnackbars({
                        type: 'error',
                    })
                } else {
                    this.reset.errors[req] = ''
                    return true
                }
            }
        },
        // === RESET PASSWORD === //
        resetPassword(reset) {
            let success = this.validationMenu(reset)

            if (success) {
                new this.Rest('POST', 'password')
                    .setBody({
                        details: {
                            name: this.reset.fields.nameAndLastname,
                            login: this.reset.fields.login,
                            phone: this.reset.fields.phone,
                            contents: this.reset.fields.contents,
                            mail: this.reset.fields.email,
                        },
                    })
                    .send()
                this.showRecovery = false
                this.setSnackbars({
                    type: 'success',
                    text: `${this.$t('global:sendToAdmin')}`,
                })
                this.reset.fields = {
                    nameAndLastname: '',
                    email: '',
                    phone: '',
                    login: '',
                    contents: '',
                }
            }
        },

        closeMenuModal() {
            this.showRecovery = false
            this.reset.fields = {
                nameAndLastname: '',
                email: '',
                phone: '',
                login: '',
                contents: '',
            }
        },

        // === TRANSITION AND CHANGED CONTAINER === //
        HelloUser() {
            this.userName = !this.userName
            setTimeout(() => {
                this.userName = !this.userName
                this.$router.push('dashboard')
            }, 2000)
        },
        // === LOGING === //
        tryLogin() {
            this.errors = ''

            new this.Rest('POST', 'login')
                .send(this.form)
                .then(response => {
                    this.errors = ''
                    this.HelloUser()
                    this.user = response.data.user
                    this.setUserSession({ time: response.data.session.time })
                    this.setPicture(response.data.pictureBinary.data)
                })

                .catch(err => {
                    if (err.response)
                        this.errors = this.$t(err.response.data.error)
                })
        },
    },
}
</script>
<style lang="scss" scoped>
/* .session--text
    width: 350px
    text-align: center */

.login__container {
    height: 100vh;
    width: 100vw;
    background-image: url('./../../assets/img/login/login-background.jpg');
    object-fit: cover;
}
.center {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 350px;
    background: rgba(255, 255, 255, 0.7);
    border-radius: 5px;
    box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.2);
    padding-top: 32px;
    min-height: 38rem;
}
.center .logo {
    margin-top: 40px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        max-width: 43%;
    }
}
.center .slogan {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;

    margin-bottom: 150px;
    span {
        color: #ababab;
    }
}
.center .footer {
    position: absolute;
    text-align: center;
    bottom: 0%;
    left: 50%;
    transform: translate(-50%, -50%);
    & a,
    & a:visited,
    & a:link {
        color: #d1d1d1;
        text-decoration: none;
    }
}
.version {
    position: absolute;
    font-size: 12px;
    z-index: 1;
    bottom: 0px;
    left: 10px;
    color: #b7b7b7;
}

.error-message {
    color: #cc0033;
    display: inline-block;
    font-size: 11px;
    transition: 3.2s;
    margin-bottom: 5px;
}

.buttons {
    min-width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0px 70px;
    margin-top: 2.5rem;
    button {
        width: 4.5rem;
        height: 30px;
        border-radius: 7px;
        font-size: 10px;
        font-weight: 500;
        cursor: pointer;
        letter-spacing: 0.892857px;
        text-indent: 0.892857px;
        box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%),
            0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
    }
    button:hover {
        border-color: linear-gradient(
            359.3deg,
            #ffcb00 -0.1%,
            #fc0 52.4%,
            #fec462 101.2%
        );
        transition: 0.5s;
    }
    .btn-login {
        background: linear-gradient(
            359.3deg,
            #ffcb00 -0.1%,
            #fc0 52.4%,
            #fec462 101.2%
        );
    }
    .btn-reset {
        background-color: rgb(245, 245, 245);
    }
}

.center form {
    padding: 0 80px;
    box-sizing: border-box;
    min-height: 100%;
}

form .txt_field {
    position: relative;
    border-bottom: 2px solid #adadad;
    width: 100%;
    margin-bottom: -5px;
}

form .txt_field:nth-child(3) {
    margin-top: 12px;
}

form .txt_field input {
    width: 100%;
    height: 20px;
    font-size: 16px;
    border: none;
    background: none;
    outline: none;
    margin-top: 0px;
}
.txt_field label {
    position: absolute;
    top: 50%;
    left: 1px;
    color: #adadad;
    transform: translateY(-50%);
    font-size: 16px;
    pointer-events: none;
    transition: 0.5s;
    margin-top: -3px;
}

.txt_field span::before {
    content: '';
    position: absolute;
    top: 24px;
    left: 0;
    width: 0%;
    height: 1px;
    background: linear-gradient(
        359.3deg,
        #ffcb00 -0.1%,
        #fc0 52.4%,
        #fec462 101.2%
    );
    transition: 0.5s;
}

.txt_field input:focus ~ label,
.txt_field input:valid ~ label {
    top: -5px;
    color: linear-gradient(359.3deg, #ffcb00 -0.1%, #fc0 52.4%, #fec462 101.2%);
}
.txt_field input:focus ~ span::before,
.txt_field input:valid ~ span::before {
    width: 100%;
}

.greetings {
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 15px;
    color: #66bb6a;
    width: max-content;
}

@media only screen and (max-width: 1440px) {
    .center {
        width: 250px;
        min-height: 500px;
        padding-top: 10px;
    }
    .buttons {
        margin-top: 30px;
        margin-bottom: 100px;
        display: grid;
        grid-template-rows: 1fr;
        grid-auto-flow: dense;
    }
    .center form {
        padding: 0 40px;
    }
    .buttons:nth-child(1) {
        order: 2;
    }
    .buttons:nth-child(2) {
        order: 1;
    }
    .center .slogan {
        margin-bottom: 50px;
    }
}
.fade-enter-active,
.fade-leave-active {
    transition: opacity 1s;
    transition-timing-function: ease-in-out;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}
</style>
